<ngx-spinner type="ball-clip-rotate-multiple"></ngx-spinner>
<div class="supplyChainMap">
  <div class="row m-0">
    <div class="col-9 p-0">
      <div class="form-group mapSearch">
        <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Location"
          autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
      </div>
      <div class="mapCls">
        <agm-map #gm [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" (zoomChange)="changeMapZoom($event)"
          [disableDefaultUI]="false" [gestureHandling]="'cooperative'" [scrollwheel]="true"
          (mapClick)="mapClicked($event)" [fitBounds]="mapBound" [fullscreenControl]='true' [mapTypeControl]='true'>
          <agm-marker-cluster [gridSize]="10" [maxZoom]="8"
            imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
            <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng"
              (markerClick)="clickedMarker(m, i)" [markerDraggable]="m.draggable"
              (dragStart)="markerDragStart(m, $event)" (dragEnd)="markerDragEnd(m, $event)" [animation]="m.animation"
              (mouseOver)="markerOver(m, infoWindow, gm)" (mouseOut)="markerOut(m, infoWindow)" [iconUrl]="m.icon"
              [agmFitBounds]="true">
              <agm-info-window [disableAutoPan]="false" #infoWindow>
                <div>
                  {{m.label}}
                </div>
              </agm-info-window>
            </agm-marker>
          </agm-marker-cluster>
          <agm-marker *ngFor="let m of vehicleMarkers; let i = index" [latitude]="m.lat" [longitude]="m.lng"
            [animation]="m.animation" (mouseOver)="markerOver(m, infoWindow, gm)" (mouseOut)="markerOut(m, infoWindow)"
            [iconUrl]="m.icon" [agmFitBounds]="true">
            <agm-info-window [disableAutoPan]="false" #infoWindow>
              <div>
                {{m.label}}
              </div>
            </agm-info-window>
          </agm-marker>
          <agm-marker *ngFor="let m of polylineMidMarkers; let i = index" [latitude]="m.lat" [longitude]="m.lng"
            [animation]="m.animation" [iconUrl]="m.icon" [markerDraggable]="m.draggable"
            (dragStart)="polyMarkerDragStart(m, $event)" (dragEnd)="polyMarkerDragEnd(m, $event)">
          </agm-marker>
          <agm-polyline *ngFor="let polyline of polylines;let i = index;" [visible]="true"
            [strokeColor]="polyline.color">
            <agm-polyline-point *ngFor="let point of polyline.path" [latitude]="point.lat" [longitude]="point.lng">
            </agm-polyline-point>
          </agm-polyline>
          <agm-direction [origin]="startPoint" [destination]="endPoint" [renderOptions]="renderOptions"
            [markerOptions]="markerOptions" [optimizeWaypoints]="false" [waypoints]="waypoints"
            (onChange)="changePolyLine($event)" (status)="getStatusDirection($event)" [visible]="routeVisible">
          </agm-direction>
        </agm-map>
      </div>
    </div>
    <div class="col-3 p-0">
      <div class="mapSidebar">
        <div class="form-group">
          <label>Select Supply Chain</label>
          <select class="form-control" [(ngModel)]="selectedSupplyChain"
            (change)="supplyChainChange(selectedSupplyChain)">
            <option *ngFor="let option of supplyChainList" [ngValue]="option">
              {{option.name}}
            </option>
          </select>
        </div>
        <div class="form-group">
          <button (click)="goToSimulation()" class="btn btn-primary mr-10">
            <i class="fa fa-play" aria-hidden="true"></i>
            Simulation
          </button>
          <div class="btn-group" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
              aria-controls="dropdown-basic">
              <i class="fa fa-cog" aria-hidden="true"></i>
              Options <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem">
                <a class="dropdown-item" (click)="addNewSupplyChain('new')">
                  <i class="fa fa-plus"></i> New Supply Chain
                </a>
              </li>
              <li role="menuitem">
                <!-- (click)="addNewSupplyChainModal.show();toggleEditMode('show')" -->
                <a class="dropdown-item" (click)="addNewSupplyChain('show')">
                  <i class="fa fa-pencil"></i> Edit Supply Chain Name
                </a>
              </li>
              <li role="menuitem">
                <a class="dropdown-item" (click)="addSupplyToHistory()">
                  <i class="fa fa-floppy-o"></i> Create Save State
                </a>
              </li>
            </ul>
          </div>
          <button (click)="aiSuggest()" class="btn btn-primary ml-10" tooltip="AI generated scheduling and data defaults">
            <i class="fa fa-magic" aria-hidden="true"></i>
            AI Suggest
          </button>
        </div>
        <div class="form-group">
          <h5>Entities</h5>
          <div class="enTree">
            <ul id="myUL">
              <li>
                <div class="liBox">
                  <span class="caret" [ngClass]="productList.length > 0 ? 'caret-down' : ''"
                    (click)="toggleTree($event, '', '', '', '')"></span>
                  <span>Product</span>
                  <button (click)="addUpdateProductMode('add', '')" class="btn btn-outline-primary btn-sm float-right"
                    tooltip="Add New"><i class="fa fa-plus"></i> Product</button>
                </div>
                <div class="clearfix"></div>
                <ul class="nested" [ngClass]="productList.length > 0 ? 'active' : ''">
                  <li *ngFor="let product of productList">
                    <div class="liBox">
                      <span (click)="addUpdateProductMode('update', product)" class="pointer">{{product.name}}</span>
                      <div class="btn-group  btn-group-sm pull-right mb-0" role="group" aria-label="Basic example">
                        <button (click)="addUpdateProductMode('clone', product)" tooltip="Clone" type="button"
                          class="btn btn-outline-primary"><i class="fa fa-clone"></i></button>
                        <button (click)="deleteProduct(product)" tooltip="Delete" type="button"
                          class="btn btn-outline-danger"><i class="fa fa-trash"></i></button>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              </li>
              <li>
                <div class="liBox">
                  <span class="caret" [ngClass]="supplyFacility?.length > 0 ? 'caret-down' : ''"
                    (click)="toggleTree($event, '', '', '', '')"></span>
                  <span>Facility</span>
                  <button (click)="addUpdateFacilityMode('add', '')" class="btn btn-outline-primary btn-sm float-right"
                    tooltip="Add New"><i class="fa fa-plus"></i> Facility</button>
                </div>
                <div class="clearfix"></div>
                <ul class="nested" [ngClass]="supplyFacility?.length > 0 ? 'active' : ''">
                  <li *ngFor="let facility of supplyFacility">
                    <div class="liBox">
                      <span *ngIf="supplyFacility?.length > 0" class="caret"
                        (click)="toggleTree($event, this.selectedSupplyChain.id, facility, '', 'facility')"></span>
                      <span (click)="addUpdateFacilityMode('update', facility)" class="pointer">
                        <img class="icon" src="{{facility.icon}}" />{{facility.name}}
                      </span>
                      <div class="btn-group  btn-group-sm pull-right mb-0" role="group" aria-label="Basic example">
                        <button (click)="addUpdateVehicleMode('add', facility, '')" tooltip="Add New" type="button"
                          class="btn btn-outline-primary"><i class="fa fa-plus"></i> Vehicle</button>
                        <button (click)="addUpdateFacilityMode('clone', facility)" tooltip="Clone" type="button"
                          class="btn btn-outline-primary"><i class="fa fa-clone"></i></button>
                        <button (click)="deleteFacility(facility)" tooltip="Delete" type="button"
                          class="btn btn-outline-danger"><i class="fa fa-trash"></i></button>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <ul class="nested">
                      <li *ngFor="let vehicle of facility.vehicleList; let i = index;">
                        <div class="liBox">
                          <span *ngIf="facility.vehicleList?.length > 0" class="caret"
                            (click)="toggleTree($event, this.selectedSupplyChain.id, facility, vehicle, 'vehicle')"></span>
                          <span (click)="addUpdateVehicleMode('update', facility, vehicle)" class="pointer">
                            <img class="icon" src="{{vehicle.icon}}" /> {{vehicle.name}}
                          </span>
                          <div class="btn-group  btn-group-sm pull-right mb-0" role="group" aria-label="Basic example">
                            <button (click)="addUpdateRouteMode('add', facility, vehicle, '')" tooltip="Add New"
                              type="button" class="btn btn-outline-primary"><i class="fa fa-plus"></i> Route</button>
                            <button (click)="addUpdateVehicleMode('clone', facility, vehicle)" tooltip="Clone"
                              type="button" class="btn btn-outline-primary"><i class="fa fa-clone"></i></button>
                            <button (click)="deleteVehicle(facility, vehicle)" tooltip="Delete" type="button"
                              class="btn btn-outline-danger"><i class="fa fa-trash"></i></button>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <ul class="nested">
                          <li *ngFor="let route of vehicle?.vehicleRoutes">
                            <div class="liBox">
                              <span (click)="addUpdateRouteMode('update', facility, vehicle, route)"
                                class="pointer">{{route.name}}</span>
                              <div class="btn-group  btn-group-sm pull-right mb-0" role="group"
                                aria-label="Basic example">
                                <!--<button (click)="addUpdateRouteMode('clone', facility, vehicle, route)" tooltip="Clone" type="button" class="btn btn-outline-primary"><i class="fa fa-clone"></i></button>-->
                                <button (click)="deleteRoute(facility, vehicle, route)" tooltip="Delete" type="button"
                                  class="btn btn-outline-danger"><i class="fa fa-trash"></i></button>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                          </li>
                        </ul>
                      </li>
                      <!--<li>
                        <span class="caret" (click)="toggleTree($event)"></span>
                        <span>V2</span>
                        <div class="btn-group  btn-group-sm pull-right mb-5" role="group" aria-label="Basic example">
                          <button tooltip="Add New" type="button" class="btn btn-outline-primary"><i class="fa fa-plus"></i> Route</button>
                          <button tooltip="Clone" type="button" class="btn btn-outline-primary"><i class="fa fa-clone"></i></button>
                          <button tooltip="Delete" type="button" class="btn btn-outline-primary"><i class="fa fa-trash"></i></button>
                        </div>
                        <div class="clearfix"></div>
                      </li>-->
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add new supply chain modal start -->
<div id="addNewSupplyChainModal" class="modal fade" tabindex="-1" role="dialog" data-backdrop="static"
  data-keyboard='false' aria-labelledby="dialog-sizes-name2">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="dialog-sizes-name2" class="modal-title pull-left">
          <span *ngIf="!editSupplyMode">Add New Supply Chain</span>
          <span *ngIf="editSupplyMode">Update Supply Chain</span>
        </h5>
        <button type="button" class="close pull-right" aria-label="Close" id="supplyChainCloseBtn"
          (click)="addNewSupplyChain('hide')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Name<span class="required">*</span></label>
          <input type="text" [(ngModel)]="supplyName" placeholder="Enter here" class="form-control">
          <small>NOTE: Name cannot be less than 4 characters</small>
        </div>
        <button (click)="addUpdateSupplyChain()" [disabled]="supplyName.length < 3" type="button"
          class="btn btn-success pull-right">
          <span *ngIf="!editSupplyMode">Add</span>
          <span *ngIf="editSupplyMode">Update</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Add new supply chain modal end -->

<!-- Delete facility/vehicle/route modal start -->
<div id="deleteFacilityModal" class="modal fade" tabindex="-1" role="dialog" data-backdrop="static"
  data-keyboard='false' aria-labelledby="dialog-sizes-name2">
  <div class="modal-dialog modal-sm">
    <div class="modal-content deleteModal">
      <div class="modal-header">
        <h6 class="modal-title pull-left">
          <span *ngIf="selectedMode === 'facility'">Remove Facility</span>
          <span *ngIf="selectedMode === 'vehicle'">Remove Vehicle</span>
          <span *ngIf="selectedMode === 'route'">Remove Route</span>
        </h6>
        <button type="button" class="close pull-right" aria-label="Close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="facilityBeforeDelData">
        <p>If you remove this Facility these entities will be affected.</p>
        <div class="form-group">
          <label>Supply Chain:</label>
          <input type="text" [(ngModel)]="selectedSupplyChain.name" disabled>
        </div>
        <div class="form-group" *ngIf="selectedMode !== 'facility'">
          <label>Facility:</label>
          <input type="text" [(ngModel)]="selectedFacility.name" disabled>
        </div>
        <div class="form-group" *ngIf="selectedMode !== 'vehicle' && (facilityBeforeDelData.vehicles?.length > 0)">
          <label>Vehicles:</label>
          <select class="form-control" size="3">
            <option *ngFor="let option of facilityBeforeDelData.vehicles">
              {{option}}
            </option>
          </select>
        </div>
        <div class="form-group" *ngIf="selectedMode !== 'route' && (facilityBeforeDelData.routes?.length > 0)">
          <label>Routes:</label>
          <select class="form-control" size="3">
            <option *ngFor="let option of facilityBeforeDelData.routes">
              {{option}}
            </option>
          </select>
        </div>
        <p *ngIf="selectedMode === 'facility'">Are you sure that you want to delete selected Facility
          <b>{{selectedFacility.name}}</b>
        </p>
        <p *ngIf="selectedMode === 'vehicle'">Are you sure that you want to delete selected Vehicle
          <b>{{selectedVehicle.name}}</b>
        </p>
        <p *ngIf="selectedMode === 'route'">Are you sure that you want to delete selected Route
          <b>{{selectedRoute.name}}</b>
        </p>
        <button data-dismiss="modal" type="button" class="btn btn-outline-secondary pull-right">
          <span>Cancel</span>
        </button>
        <button *ngIf="selectedMode === 'facility'" (click)="removeFacilityConfirm(facilityId)" type="button"
          class="btn btn-outline-danger pull-right mr5">
          <span>Remove</span>
        </button>
        <button *ngIf="selectedMode === 'vehicle'" (click)="removeVehicleConfirm()" type="button"
          class="btn btn-outline-danger pull-right mr5">
          <span>Remove</span>
        </button>
        <button *ngIf="selectedMode === 'route'" (click)="removeRouteConfirm()" type="button"
          class="btn btn-outline-danger pull-right mr5">
          <span>Remove</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Delete facility/vehicle/route modal end -->

<!-- Add/update product modal start -->
<app-modal #productModal class="modal-demo productFormCls" id="showProduct" [backdrop]="false" [inViewport]="true">
  <ng-container class="app-modal-header"><span *ngIf="!productUpdate">Add &nbsp;</span><span
      *ngIf="productUpdate">Update &nbsp;</span> Product <i (click)="closeModalLib('showProduct', '')"
      class="ui-icon dt-icon-close crossCls"></i></ng-container>
  <ng-container class="app-modal-body">
    <form [formGroup]="productForm" autocomplete="off">
      <div class="form-group">
        <label>Name<span class="required">*</span></label>
        <input type="text" class="form-control" formControlName="productName" [(ngModel)]="productName"
          placeholder="Enter here">
      </div>
      <div class="form-group">
        <label>Cost</label>
        <input type="number" class="form-control" formControlName="productPrice" [(ngModel)]="productPrice"
          placeholder="Enter here">
      </div>
      <div class="form-group">
        <label>Weight <span>( In Kilogram )</span></label>
        <input type="number" class="form-control" formControlName="productWeight" [(ngModel)]="productWeight"
          placeholder="Enter here">
      </div>
      <div class="form-group">
        <label>Size <span>( In m3 )</span></label>
        <input type="number" class="form-control" formControlName="productCubeSize" [(ngModel)]="productCubeSize"
          placeholder="Enter here">
      </div>
    </form>
  </ng-container>
  <ng-container class="app-modal-footer">
    <button type="button" class="button button2 btn btn-outline-danger" id="closeProductForm"
      (click)="closeModalLib('showProduct', '')">Cancel</button>
    <button class="button button1 btn btn-outline-primary" style="float: right;" [disabled]="productForm.invalid"
      (click)="addUpdateProduct()" type="button">
      <span *ngIf="!productUpdate">Save</span>
      <span *ngIf="productUpdate">Update</span>
    </button>
  </ng-container>
</app-modal>
<!-- Add/update product modal end -->

<!-- Add/update facility modal start -->
<app-modal #facilityModal class="nested-modal-demo1 productFormCls facilityWidth" id="showFacility" [backdrop]="false"
  [inViewport]="true">
  <ng-container class="app-modal-header"><span *ngIf="!facilityUpdate">Add &nbsp;</span><span
      *ngIf="facilityUpdate">Update &nbsp;</span> Facility <i (click)="closeModalLib('showFacility', facilityId)"
      class="ui-icon dt-icon-close crossCls"></i></ng-container>
  <ng-container class="app-modal-body">
    <form [formGroup]="facilityForm" autocomplete="off">
      <div class="form-row">
        <div class="form-group col-6">
          <label>Name<span class="required">*</span></label>
          <input type="text" class="form-control" formControlName="facilityName" [(ngModel)]="facilityName"
            placeholder="Enter here">
        </div>
        <div class="form-group col-6">
          <label>Type<span class="required">*</span></label>
          <select (change)="changeFacilityType(selectedfacilityType)" [(ngModel)]="selectedfacilityType" class="form-control" formControlName="facilityType">
            <option *ngFor="let option of facilityTypes" [ngValue]="option">
              {{option.name}}
            </option>
          </select>
        </div>
      </div>
      <!--<div class="form-group">
        <label>Address</label>
        <textarea class="form-control" formControlName="faciAddress" [(ngModel)]="faciAddress" placeholder="Enter here" rows="2"></textarea>
      </div>-->
      <div class="form-row">
        <div class="form-group col-6">
          <label>Max Storage Capacity <span>( In m3 )</span><span class="required">*</span></label>
          <input type="number" class="form-control" formControlName="storageCapacity" [(ngModel)]="storageCapacity"
            placeholder="Enter here">
        </div>
        <div class="form-group col-6">
          <label>Hourly Operating Cost<span class="required">*</span></label>
          <input type="number" class="form-control" formControlName="operatingCost" [(ngModel)]="operatingCost"
            placeholder="Enter here">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-6">
          <label>Hourly Rent Cost <span>( Per m3 )</span><span class="required">*</span></label>
          <input type="number" class="form-control" formControlName="rentCost" [(ngModel)]="rentCost"
            placeholder="Enter here">
        </div>
        <div class="form-group col-6">
          <label>Hourly Carbon Output <span>( In kg )</span><span class="required">*</span></label>
          <input type="number" class="form-control" formControlName="carbonOutput" [(ngModel)]="carbonOutput"
            placeholder="Enter here">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-4">
          <label>Hourly energy Cost<span class="required">*</span></label>
          <input type="number" class="form-control" formControlName="energyCost" [(ngModel)]="energyCost"
            placeholder="Enter here">
        </div>
        <div class="form-group col-4">
          <label>Hourly labor Cost<span class="required">*</span></label>
          <input type="number" class="form-control" formControlName="laborCost" [(ngModel)]="laborCost"
            placeholder="Enter here">
        </div>
        <div class="form-group col-4">
          <label>Total Area<span class="required">*</span></label>
          <input type="number" class="form-control" formControlName="totalArea" [(ngModel)]="totalArea"
            placeholder="Enter here">
        </div>
      </div>
    </form>
    <div class="weekSchedule">
      <div class="form-row">
        <div class="form-group col-6">
          <label>Display week number</label>
          <select class="form-control" [(ngModel)]="selectedWeek" (change)="selWeekListUpdate()">
            <option *ngFor="let week of noOfWeeksList" [ngValue]="week">
              {{week}}
            </option>
          </select>
        </div>
        <div class="form-group col-6">
          <label> </label>
          <div class="">
            <button (click)="openProdAddModal()" class="btn btn-outline-primary" tooltip="Add product in facility"
              style="padding: 2px 10px;">
              <i class="fa fa-plus"></i> Product
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p style="margin-bottom: 1px;">Selected weeks</p>
          <div class="selWeekCls mb-5">
            <ul class=" weekList">
              <li *ngFor="let weekNo of selectedWeekList; let i = index;" class=""
                [ngClass]="highlightWeek(weekNo)">
                <span (click)="selectedWeek = weekNo" class="pointer">Week #{{weekNo}}</span>
                <div class="btn-group  btn-group-sm" role="group" aria-label="Basic example">
                  <button (click)="cloneWeekFn(weekNo)" tooltip="Clone" type="button" class="btn btn-outline-primary"><i
                      class="fa fa-clone"></i></button>
                  <button (click)="removeWeekFn(weekNo)" [hidden]="weekNo === 1" tooltip="Delete" type="button"
                    class="btn btn-outline-danger"><i class="fa fa-trash"></i></button>
                </div>
              </li>
              <div class="clearfix"></div>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="productTable">
      <div id="accordion">
        <div *ngFor="let col of facilityScheduleData; let i = index;">
          <div *ngIf="col.week_number === this.selectedWeek" class="card">
            <div class="card-header week-content" id="headingOne">
              <div class="left-content">
                <div class="day-name-wrapper">
                  <div class="dayName">
                    <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse' + i"
                      aria-expanded="true" [attr.area-control]="'collapse' + i">
                      <span>Day {{col.day_of_week}} (Week #{{col.week_number}})</span>
                    </button>
                  </div>
                  <div class="rightPush">
                    <div class="dayTime" [ngClass]="{'disabledColor': col.closed === true}">
                      <timepicker [(ngModel)]="col.start_time" [min]="minTime" [max]="maxTime" [showMeridian]="false"
                        [showSpinners]="false" [disabled]="col.closed" [mousewheel]="false"
                        (ngModelChange)="isStartTimeChange($event, col, 'facility')" [hourStep]="1" [minuteStep]="1">
                      </timepicker>
                    </div>
                    <div class="dayTime" style="padding: 3px 5px;background-color: #f9f9f9;"> - </div>
                    <div class="dayTime" [ngClass]="{'disabledColor': col.closed === true}">
                      <timepicker [(ngModel)]="col.end_time" [min]="minTime" [max]="maxTime" [showMeridian]="false"
                        [showSpinners]="false" [disabled]="col.closed" [mousewheel]="false"
                        (ngModelChange)="isEndTimeChange($event, col)" [hourStep]="1" [minuteStep]="1"></timepicker>
                    </div>
                  </div>
                </div>
                <div class="closed-checkbox custom-control custom-checkbox">
                  <input [checked]="col.closed === true" [(ngModel)]="col.closed" (click)="closeDay(col, $event)"
                    type="checkbox" class="custom-control-input" style="z-index: 9999;">
                  <label class="custom-control-label">Closed</label>
                </div>
              </div>
              <div class='right-content'>
                <button *ngIf='col.day_of_week === 1' class='btn btn-outline-primary btn-sm'
                  (click)="handleApplyToWeek(col, 'week')">Apply to Week</button>
                <button *ngIf='col.day_of_week === 1 && applyToWeek' class='btn btn-outline-primary btn-sm'
                  (click)="handleApplyToWeek(col, 'undo')">Undo</button>
              </div>
            </div>
            <div *ngIf="col.week_number === this.selectedWeek" id="collapse{{i}}" [ngClass]="i === 0 ? 'show' : ''"
              class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body">
                <div class="prodDataTable">
                  <table class="table headerOfTable">
                    <thead>
                      <tr>
                        <th style="width: 45%">Product</th>
                        <th style="width: 12%">Demand per hour</th>
                        <th style="width: 12%">Production per hour</th>
                        <th *ngIf="col.week_number === 1 && col.day_of_week === 1" style="width: 12%">Quantity On hand
                        </th>
                        <th *ngIf="col.week_number === 1 && col.day_of_week === 1" style="width: 12%">Storage Used</th>
                        <th style="width: 7%">Action</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="col.facility_schedule_products_attributes?.length > 0">
                      <ng-container *ngFor="let prod of col.facility_schedule_products_attributes; let idx = index;">
                        <tr *ngIf="prod._destroy === false">
                          <td>{{prod.product_name}}</td>
                          <td>
                            <app-inline-edit-table (input)="calculateSelectedDayProductTotalValue('', i)"
                              [(ngModel)]="prod.demand" type="text"> </app-inline-edit-table>
                          </td>
                          <td>
                            <app-inline-edit-table (input)="calculateSelectedDayProductTotalValue('', i)"
                              [(ngModel)]="prod.production" type="text"> </app-inline-edit-table>
                          </td>
                          <td *ngIf="col.week_number === 1 && col.day_of_week === 1">
                            <app-inline-edit-table (ngModelChange)="getProductOldValues(prod)"
                              (input)="calculateStorage(prod, i)" [(ngModel)]="prod.on_hand_amount" type="text">
                            </app-inline-edit-table>
                          </td>
                          <td *ngIf="col.week_number === 1 && col.day_of_week === 1">{{prod.storage_used}}</td>
                          <td>
                            <i (click)="removeProdcutInFacility(prod)" class="fa fa-remove danger pointer"></i>
                          </td>
                        </tr>
                      </ng-container>
                      <tr *ngIf="col.facility_schedule_products_attributes?.length > 0">
                        <td><b>Total</b></td>
                        <td><b>{{(col.product_total_demand).toFixed(2)}}</b></td>
                        <td><b>{{(col.product_total_production).toFixed(2)}}</b></td>
                        <td *ngIf="col.week_number === 1 && col.day_of_week === 1">
                          <b>{{(col.product_total_quantity).toFixed(2)}}</b>
                        </td>
                        <td *ngIf="col.week_number === 1 && col.day_of_week === 1">
                          <b>{{(col.product_total_storage).toFixed(2)}} ({{col.product_total_storage_per}}%)</b>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="col.facility_schedule_products_attributes?.length <= 0">
                      <tr>
                        <td colspan="6" class="text-center">Product not added yet.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fTip">
      <p>***Tip: Please click on map to place facility, then you can change position by dragging icon.</p>
    </div>
    <!-- Add product in facility modal start -->
    <app-modal #addProductInFacilityModal class="nested-modal-demo2 productFormCls prodFaci" id="prodInFacility"
      [backdrop]="false">
      <ng-container class="app-modal-header">Add Product <i (click)="closeModalLib('prodInFacility', '')"
          class="ui-icon dt-icon-close crossCls"></i></ng-container>
      <ng-container class="app-modal-body">
        <form [formGroup]="addProductInFacilityForm" autocomplete="off">
          <div class="form-group">
            <label>Product</label>
            <select [(ngModel)]="selectedProdInFacility" class="form-control" formControlName="pName">
              <option *ngFor="let option of productList" [ngValue]="option">
                {{option.name}}
              </option>
            </select>
          </div>
          <div class="form-row">
            <div class="form-group col-6">
              <label>Demand per hour</label>
              <input type="number" class="form-control" formControlName="pDemand" [(ngModel)]="pDemand"
                placeholder="Enter here">
            </div>
            <div class="form-group col-6">
              <label>Production per hour</label>
              <input type="number" class="form-control" formControlName="pProduction" [(ngModel)]="pProduction"
                placeholder="Enter here">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-6">
              <label>Quantity On hand</label>
              <input (input)="calculateCapacityInProduct()" type="number" class="form-control"
                formControlName="pOnHandAmount" [(ngModel)]="pOnHandAmount" placeholder="Enter here">
            </div>
            <div class="form-group col-6">
              <label>Capacity Used (In %)</label>
              <input type="text" readonly class="form-control" formControlName="pCapacityUsed"
                [(ngModel)]="pCapacityUsed" placeholder="">
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container class="app-modal-footer">
        <button type="button" class="button button2 btn btn-outline-danger" id="pCloseBtn"
          (click)="closeModalLib('prodInFacility', '')">Cancel</button>
        <button class="button button1 btn btn-outline-primary" style="float: right;"
          [disabled]="addProductInFacilityForm.invalid" (click)="addProductFn()" type="button">
          Add
        </button>
      </ng-container>
    </app-modal>
    <!-- Add product in facility modal end -->
    <!-- Copy week in facility modal start -->
    <app-modal #acopyWeekInFacilityModal class="nested-modal-demo2 productFormCls prodFaci" id="copyWeekInFacility"
      [backdrop]="false">
      <ng-container class="app-modal-header">Copy Week Data of #{{copyWeekNumber}} <i
          (click)="closeModalLib('copyWeekInFacility', '')" class="ui-icon dt-icon-close crossCls"></i></ng-container>
      <ng-container class="app-modal-body">
        <div class="form-group col-12">
          <label>Select Week No</label>
          <ng-multiselect-dropdown [placeholder]="'Please select weeks'" [settings]="dropdownSettings"
            [data]="weekDropData" [(ngModel)]="selectedNoOfWeeks">
          </ng-multiselect-dropdown>
        </div>
        <small>** You can select multiple week number to copy selected week data.</small>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </ng-container>
      <ng-container class="app-modal-footer">
        <button type="button" class="button button2 btn btn-outline-danger"
          (click)="closeModalLib('copyWeekInFacility', '')">Cancel</button>
        <button class="button button1 btn btn-outline-primary" style="float: right;" (click)="addCopiedWeeks()"
          type="button">
          Add
        </button>
      </ng-container>
    </app-modal>
    <!-- Copy week in facility modal end -->
  </ng-container>
  <ng-container class="app-modal-footer">
    <button type="button" class="button button2 btn btn-outline-danger" id="closeFacilityForm"
      (click)="closeModalLib('showFacility', facilityId)">Cancel</button>
    <button class="button button1 btn btn-outline-primary" style="float: right;" [disabled]="facilityForm.invalid"
      (click)="addUpdateFacilityFn()" type="button">
      <span *ngIf="!facilityUpdate">Save</span>
      <span *ngIf="facilityUpdate">Update</span>
    </button>
  </ng-container>
</app-modal>
<!-- Add/update facility modal end -->


<!-- Add/update Vehicle in facility modal start -->
<app-modal #vehicleModal class="modal-demo productFormCls" id="showVehicle" [backdrop]="false" [inViewport]="true">
  <ng-container class="app-modal-header"><span *ngIf="!vehicleUpdate">Add &nbsp;</span><span
      *ngIf="vehicleUpdate">Update &nbsp;</span> Vehicle <i (click)="closeModalLib('showVehicle', '')"
      class="ui-icon dt-icon-close crossCls"></i></ng-container>
  <ng-container class="app-modal-body">
    <form [formGroup]="vehicleForm" autocomplete="off">
      <div class="form-group">
        <label>Name<span class="required">*</span></label>
        <input type="text" class="form-control" formControlName="vName" [(ngModel)]="vehicleName"
          placeholder="Enter here">
      </div>
      <div class="form-group">
        <label>Select Vehicle Type<span class="required">*</span></label>
        <select class="form-control" (change)="changeVehicleType(selectedVehicleType)" [(ngModel)]="selectedVehicleType" formControlName="vType">
          <option *ngFor="let vehicle of vehicleTypeList" [ngValue]="vehicle">
            {{vehicle.name}}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Carry Volume (In m3)<span class="required">*</span></label>
        <input type="number" class="form-control" formControlName="vVolume" [(ngModel)]="vehicleVolume"
          placeholder="Enter here">
      </div>
      <div class="form-group">
        <label>Max Weight <span>( In Kilogram )</span><span class="required">*</span></label>
        <input type="number" class="form-control" formControlName="vWeight" [(ngModel)]="vehicleWeight"
          placeholder="Enter here">
      </div>
      <div class="form-group">
        <label>Speed <span>( Km/h )</span><span class="required">*</span></label>
        <input type="number" class="form-control" formControlName="vSpeed" [(ngModel)]="vehicleSpeed"
          placeholder="Enter here">
      </div>
      <div class="form-group">
        <label>Operating Cost <span>( Per Km )</span><span class="required">*</span></label>
        <input type="number" class="form-control" formControlName="vCost" [(ngModel)]="vehicleCost"
          placeholder="Enter here">
      </div>
      <div class="form-group">
        <label>Carbon Generated <span>( Per Km )</span><span class="required">*</span></label>
        <input type="number" class="form-control" formControlName="vCarbonOut" [(ngModel)]="vehicleCarbonOutput"
          placeholder="Enter here">
      </div>
      <!--<div class="form-group">
        <label>Delay Between Departures <span>( Hours )</span></label>
        <input type="number" class="form-control" formControlName="vDelay" [(ngModel)]="vehicleDelay" placeholder="Enter here">
      </div>-->
      <div class="form-group" *ngIf="selectedFacility && selectedFacility.name">
        <label>Start Facility:- <span><b>{{selectedFacility.name}}</b></span></label>
      </div>
    </form>
  </ng-container>
  <ng-container class="app-modal-footer">
    <button type="button" class="button button2 btn btn-outline-danger"
      (click)="closeModalLib('showVehicle', '')">Cancel</button>
    <button class="button button1 btn btn-outline-primary" style="float: right;" [disabled]="vehicleForm.invalid"
      (click)="addUpdateVehicle()" type="button">
      <span *ngIf="!vehicleUpdate">Save</span>
      <span *ngIf="vehicleUpdate">Update</span>
    </button>
  </ng-container>
</app-modal>
<!-- Add/update  Vehicle in facility modal end -->

<!-- Add/update Route modal start -->
<app-modal #routeModal class="modal-demo productFormCls facilityWidth" id="showRoutes" [backdrop]="false"
  [inViewport]="true">
  <ng-container class="app-modal-header"><span *ngIf="!routeUpdate">Add &nbsp;</span><span *ngIf="routeUpdate">Update
      &nbsp;</span> Route <i (click)="closeModalLib('showRoutes', '')"
      class="ui-icon dt-icon-close crossCls"></i></ng-container>
  <ng-container class="app-modal-body">
    <form [formGroup]="routeForm" autocomplete="off">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Name<span class="required">*</span></label>
        <div class="col-sm-10">
          <input type="text" class="form-control" formControlName="routeName" [(ngModel)]="routeName"
            placeholder="Enter here">
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-6">
        <div class="widthManage"><span>Distance: </span> <b>{{getCommaSepratedValue(routeDistance)}}</b> Km</div>
      </div>
      <div class="col-6">
        <div class="widthManage"><span>Time: </span> <b>{{routeTime === 0 ? '00:00' : routeTime}}</b> H</div>
      </div>
    </div>
    <div class="row">
      <div class="col-6" *ngIf="selectedVehicle && selectedVehicle.name">
        <div class="widthManage"><span>Vehicle: </span> <b>{{selectedVehicle.name}}</b> </div>
      </div>
      <div class="col-6" *ngIf="selectedVehicle && selectedVehicle.max_volume">
        <div class="widthManage"><span>Carry Volume: </span> <b>{{selectedVehicle.max_volume}}</b> (m3)</div>
      </div>
    </div>
    <div class="row">
      <div class="col-6" *ngIf="selectedVehicle && selectedVehicle.max_weight">
        <div class="widthManage"><span>Max Weight: </span> <b>{{getCommaSepratedValue(selectedVehicle.max_weight)}}</b> Kg</div>
      </div>
    </div>
    <div class="weekSchedule">
      <div class="form-row">
        <div class="form-group col-4">
          <label>Week Number</label>
          <select class="form-control" [(ngModel)]="selectedRouteWeek" (change)="selRouteWeekListUpdate()">
            <option *ngFor="let week of noOfWeeksList" [ngValue]="week">
              {{week}}
            </option>
          </select>
        </div>
        <div class="form-group col-4">
          <label>Select Stop</label>
          <select class="form-control" [(ngModel)]="selectedRouteFacility">
            <option *ngFor="let facility of facilityListForRouteStop" [ngValue]="facility">
              {{facility.name}}
            </option>
          </select>
        </div>
        <div class="form-group col-4">
          <label> </label>
          <div class="">
            <button (click)="addStopsToRoute()" class="btn btn-outline-primary" style="padding: 2px 10px;">
              Add Stop to Route
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p style="margin-bottom: 1px;">Selected weeks</p>
          <div class="selWeekCls mb-5">
            <ul class=" weekList">
              <li *ngFor="let weekNo of selectedRouteWeekList; let i = index;" class=""
                [ngClass]="this.selectedRouteWeek === weekNo ? 'active' : ''">
                <span (click)="selectedRouteWeek = weekNo" class="pointer">Week #{{weekNo}}</span>
                <div class="btn-group  btn-group-sm" role="group" aria-label="Basic example">
                  <button (click)="cloneWeekRouteFn(weekNo)" tooltip="Clone" type="button"
                    class="btn btn-outline-primary"><i class="fa fa-clone"></i></button>
                  <button (click)="removeWeekRouteFn(weekNo)" [hidden]="weekNo === 1" tooltip="Delete" type="button"
                    class="btn btn-outline-danger"><i class="fa fa-trash"></i></button>
                </div>
              </li>
              <div class="clearfix"></div>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="productTable">
      <div id="accordion2">
        <div *ngFor="let col of routeScheduleData; let i = index;">
          <div *ngIf="col?.week_number === this.selectedRouteWeek" class="card">
            <div class="card-header" id="headingNewOne">
              <div class="leftContent" style="width: 80%;">
                <div class="dayName">
                  <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapseRoute' + i"
                    aria-expanded="true" [attr.area-control]="'collapseRoute' + i">
                    <span>Day {{col.day_of_week}} (Week #{{col.week_number}})</span>
                  </button>
                </div>
                <!--<div class="dayTime">
                  <timepicker [(ngModel)]="col.end_time" [min]="minTime" [max]="maxTime" [showMeridian]="false" [showSpinners]="false" [disabled]="col.closed" [mousewheel]="false" (ngModelChange)="isEndTimeChange($event, col)"></timepicker>
                </div>
                <div class="dayTime" style="padding: 3px 5px;"> - </div>-->
                <div class="dayTime" [ngClass]="{'disabledColor': col.closed === true}">
                  <timepicker [(ngModel)]="col.start_time" [min]="col.minTime" [max]="maxTime" [showMeridian]="false"
                    [showSpinners]="false" [disabled]="col.closed" [mousewheel]="false"
                    (change)="ariaExpanded = i; isStartTimeChange(col.start_time, col, 'route')"></timepicker>
                </div>
                <div class="dayTime same-day-end-time disabledColor">
                  <timepicker [(ngModel)]="col.end_time" [showMeridian]="false"
                    [showSpinners]="false" [mousewheel]="false" [disabled]="true"></timepicker>
                </div>
              </div>
              <div class="custom-control custom-checkbox float-right" [ngClass]="col.automaticClosed ? 'disabled-day-section' : ''">
                <input [checked]="col.closed === true" [(ngModel)]="col.closed" (click)="closeDay(col, $event)"
                  type="checkbox" class="custom-control-input" style="z-index: 9999;">
                <label class="custom-control-label">Close</label>
              </div>
            </div>

            <div [ngClass]="{'disabled-day-section': col.automaticClosed, 'show': i === 0}" *ngIf="col.week_number === this.selectedRouteWeek" id="collapseRoute{{i}}" class="collapse"
             aria-labelledby="headingNewOne" data-parent="#accordion2">
              <div class="card-body">
                <div class="prodDataTable">
                  <table class="table headerOfTable">
                    <thead>
                      <tr>
                        <th style="width: 32%">Stop</th>
                        <th style="width: 12%">Wait Time</th>
                        <th style="width: 25%">Product</th>
                        <th style="width: 12%">Delivery Quantity</th>
                        <th style="width: 12%">Pickup Quantity</th>
                        <th style="width: 7%">Action</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="col.route_stops_attributes?.length > 0">
                      <ng-container *ngFor="let stop of col.route_stops_attributes; let ind = index;">
                        <tr *ngIf="stop._destroy === false && !stop.is_return">
                          <td>
                            <span
                              *ngIf="stop.end_facility_data && stop.end_facility_data.name">{{stop.end_facility_data.name}}</span>
                            <div *ngIf="!stop.is_return" class="">
                              <button (click)="openProdAddInRouteModal(stop, ind)" class="btn btn-primary"
                                tooltip="Add Product in Route" style="padding: 5px 10px;font-size: 13px;">
                                <i class="fa fa-plus"></i> Product
                              </button>
                            </div>
                          </td>
                          <td>
                            <div *ngIf="!stop.is_return">
                              <app-inline-edit-table [(ngModel)]="stop.stop_time" type="text" (input)="updateWaitTimeOfWeek(col.day_of_week , col.week_number , $event)"> </app-inline-edit-table>
                            </div>
                            <div *ngIf="stop.is_return">
                              {{stop.stop_time}}
                            </div>
                          </td>
                          <!--<span *ngIf="stop.end_facility_data && stop.end_facility_data.stopTime">{{stop.end_facility_data.stopTime}}</span>-->
                          <td colspan="5">
                            <table style="width: 100%;">
                              <tbody *ngIf="stop.route_stop_products_attributes?.length > 0">
                                <ng-container *ngFor="let prod of stop.route_stop_products_attributes">
                                  <tr *ngIf="prod && prod._destroy === false">
                                    <td style="width: 40%;">{{prod.product_name}}</td>
                                    <td *ngIf="!stop.is_return" style="width: 25%;">
                                      <app-inline-edit-table [(ngModel)]="prod.drop_off_quantity" class="drop-off-quantity"
                                        (emitOnEditEvent)="getOldValues(prod )" (emitBlurEvent)="calculateTotalPickDropQuantity(prod , col.day_of_week , col.week_number , $event)"
                                        type="text"> </app-inline-edit-table>
                                    </td>
                                    <td *ngIf="stop.is_return" style="width: 25%;">
                                      {{prod.drop_off_quantity}}
                                    </td>
                                    <td *ngIf="!stop.is_return" style="width: 25%;">
                                      <app-inline-edit-table [(ngModel)]="prod.pickup_quantity"  class="pickup-quantity"
                                        (emitOnEditEvent)="getOldValues(prod)" (emitBlurEvent)="calculateTotalPickDropQuantity(prod , col.day_of_week , col.week_number , $event)"
                                        type="text"> </app-inline-edit-table>
                                    </td>
                                    <td *ngIf="stop.is_return" style="width: 25%;">
                                      {{prod.pickup_quantity}}
                                    </td>
                                    <td style="width: 8%;">
                                      <i (click)="removeProdcutInRoute(prod, ind)" *ngIf="!stop.is_return"
                                        tooltip="Remove Product" placement="left"
                                        class="fa fa-remove danger pointer"></i>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <i (click)="removeStopInRoute(stop)" *ngIf="!stop.is_return" tooltip="Remove Stop"
                              placement="left" class="fa fa-remove danger pointer"
                              style="font-size: 18px;background: #eee;padding: 2px;border: 1px solid #afacac;"></i>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                    <tbody *ngIf="col.route_stops_attributes?.length <= 0">
                      <tr>
                        <td colspan="6" class="text-center">Stop not added yet.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add product in Route Stop modal start -->
    <app-modal #addProductInRouteModal class="nested-modal-demo2 productFormCls prodFaci" id="prodInRouteStop"
      [backdrop]="false">
      <ng-container class="app-modal-header">Add Product <i (click)="closeModalLib('prodInRouteStop', '')"
          class="ui-icon dt-icon-close crossCls"></i></ng-container>
      <ng-container class="app-modal-body">
        <form [formGroup]="addProductInStopForm" autocomplete="off">
          <div *ngIf="selectedFacility && selectedFacility.facility_schedule_products" class="form-group">
            <label>Product</label>
            <select [(ngModel)]="selectedProdInRouteStop" (change)="changeProduct()" class="form-control"
              formControlName="prodName">
              <option *ngFor="let option of selectedFacility.facility_schedule_products" [ngValue]="option">
                {{option.product_name}}
              </option>
            </select>
          </div>
          <!--<div class="form-row">
            <div class="form-group col-6">
              <label>Demand per day</label>
              <input type="number" readonly class="form-control" formControlName="pDemand" [(ngModel)]="stopProdDemand" placeholder="Enter here">
            </div>
            <div class="form-group col-6">
              <label>Production per day</label>
              <input type="number" readonly class="form-control" formControlName="pProduction" [(ngModel)]="stopProdProduction" placeholder="Enter here">
            </div>
          </div>-->
          <div class="form-row">
            <!--<div class="form-group col-6">
              <label>Quantity On hand</label>
              <input type="number" readonly class="form-control" formControlName="pOnHandAmount" [(ngModel)]="stopProdOnHandAmount" placeholder="Enter here">
            </div>-->
            <div class="form-group col-6">
              <label>Delivery Quantity</label>
              <input type="number" class="form-control" formControlName="prodDeliveryQty" [(ngModel)]="prodDeliveryQty"
                placeholder="">
            </div>
            <div class="form-group col-6">
              <label>Pickup Quantity </label>
              <input type="number" class="form-control" formControlName="prodPickupQty" [(ngModel)]="prodPickupQty"
                placeholder="">
            </div>
          </div>
          <!--<div class="form-row">
            <div class="form-group col-6">
              <label>Pickup Quantity </label>
              <input type="number" class="form-control" formControlName="prodPickupQty" [(ngModel)]="prodPickupQty" placeholder="">
            </div>
          </div>-->
        </form>
      </ng-container>
      <ng-container class="app-modal-footer">
        <button type="button" class="button button2 btn btn-outline-danger"
          (click)="closeModalLib('prodInRouteStop', '')">Cancel</button>
        <button class="button button1 btn btn-outline-primary" style="float: right;"
          [disabled]="addProductInStopForm.invalid" (click)="addProductInStopRoute()" type="button">
          Add
        </button>
      </ng-container>
    </app-modal>
    <!-- Add product in Route Stop modal end -->
    <!-- Copy week in route modal start -->
    <app-modal #acopyWeekInRouteModal class="nested-modal-demo2 productFormCls prodFaci" id="copyWeekInRoute"
      [backdrop]="false">
      <ng-container class="app-modal-header">Copy Week Data of #{{copyWeekNumberRoute}} <i
          (click)="closeModalLib('copyWeekInRoute', '')" class="ui-icon dt-icon-close crossCls"></i></ng-container>
      <ng-container class="app-modal-body">
        <div class="form-group col-12">
          <label>Select Week No</label>
          <ng-multiselect-dropdown [placeholder]="'Please select weeks'" [settings]="dropdownSettings"
            [data]="weekDropData" [(ngModel)]="selectedNoOfWeeksInRoute">
          </ng-multiselect-dropdown>
        </div>
        <small>** You can select multiple week number to copy selected week data.</small>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </ng-container>
      <ng-container class="app-modal-footer">
        <button type="button" class="button button2 btn btn-outline-danger"
          (click)="closeModalLib('copyWeekInRoute', '')">Cancel</button>
        <button class="button button1 btn btn-outline-primary" style="float: right;" (click)="addCopiedWeeksInRoutes()"
          type="button">
          Add
        </button>
      </ng-container>
    </app-modal>
    <!-- Copy week in route modal end -->
  </ng-container>
  <ng-container class="app-modal-footer">
    <button type="button" class="button button2 btn btn-danger"
      (click)="closeModalLib('showRoutes', '')">Cancel</button>
    <button class="button button1 btn btn-primary" style="float: right;" [disabled]="routeForm.invalid"
      (click)="addUpdateRoute()" type="button">
      <span *ngIf="!routeUpdate">Save</span>
      <span *ngIf="routeUpdate">Update</span>
    </button>
  </ng-container>
</app-modal>
<!-- Add/update  Route in facility modal end -->


<!-- ai suggest modal start -->
<div bsModal id="aiSuggestModal" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name2">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">AI Suggestions</h5>
        <button type="button" class="close pull-right" aria-label="AI Suggest" id="aiSuggestButton" (click)="openAiSuggestModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" innerHTML="{{aiSuggestions}}">
    </div>
  </div>
</div>
<!-- ai suggest modal end -->